import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MainScreen from "../components/mainScreen"
import Card from "../components/card"
import Order from "../components/order"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import b from "../images/russian.jpg"
import c from "../images/zhi-shi.jpg"
import eng from "../images/english-cover.jpg"
import engLet from "../images/englet-1.jpg"
import rusKids from "../images/ruskids-cover.jpg"
import math from "../images/math-cover.jpg"
import rusUz from "../images/rusUz-cover.jpg"

//urls for pictures
const urlB = b
const urlC = c
const urlEng = eng
const urlEngLet = engLet

//urls for pages
const linkUrl1 = "/propis-1"
const linkUrl2 = "/propis-2"
const linkUrl3 = "/propis-eng"
const linkUrl4 = "/propis-englet"
const linkUrl5 = "/ruskids"
const linkUrl6 = "/matematika"
const linkUrl7 = "/rus-perehod"

const mockData = [
  {
    id: 1,
    upTitle: "Рабочая тетрадь",
    title: "Прописи русский язык",
    description:
      "Прописи- большая рабочая тетрадь для овладения навыками письма. Подойдет для школьников начальных классов  и взрослых, которые хотят улучшить свой почерк.",
    url: urlB,
    linkUrl: linkUrl1,
  },
  {
    id: 2,
    upTitle: "Рабочая тетрадь",
    title: "Прописи ЖИ-ШИ, ЧА-ЩА, ЧУ-ЩУ",
    description:
      "Прописи ЖИ-ШИ, ЧА-ЩА, ЧУ-ЩУ- самый лучший вариант запомнить правило и научиться правильно писать с ним слова.",
    url: urlC,
    linkUrl: linkUrl2,
  },
  {
    id: 3,
    upTitle: "Рабочая тетрадь",
    title: "Прописи английский язык",
    description:
      "Пропись по английскому языку подходит для дошкольников и младших школьников, начинающих изучать английский язык, для тех, кто делает первые шаги в занятиях английским.",
    url: urlEngLet,
    linkUrl: linkUrl4,
  },
  {
    id: 4,
    upTitle: "Рабочая тетрадь",
    title: "Прописи английский язык",
    description:
      "Прописи по английскому языку предназначены для младших школьников.",
    url: urlEng,
    linkUrl: linkUrl3,
  },
  {
    id: 5,
    upTitle: "Рабочая тетрадь",
    title: "Прописи русский язык",
    description:
      "Прописи по русскому языку для малышей и дошкольников содержат ряд последовательных упражнений для развития мелкой моторики по принципу от простого к сложному.",
    url: rusKids,
    linkUrl: linkUrl5,
  },
  {
    id: 6,
    upTitle: "Рабочая тетрадь",
    title: "Прописи математика",
    description:
      "Рабочая тетрадь по математике предназначена для дошкольников и младших школьников, для подготовки руки ребенка к написанию цифр, изучения счета, решения примеров на вычитание и сложение.",
    url: math,
    linkUrl: linkUrl6,
  },
  {
    id: 7,
    upTitle: "Рабочая тетрадь",
    title: "Прописи русский язык",
    description:
      "Прописи по русскому языку переход на широкую линейку подходят для школьников 2 и 3 класса, кто хочет улучшить свой почерк, кто совершенствует навыки письма.",
    url: rusUz,
    linkUrl: linkUrl7,
  },
]

const IndexPage = () => (
  <Layout>
    <Seo title="Home" lang="ru" />
    <MainScreen />
    <Card mockData={mockData} />
    <Order />
  </Layout>
)

export default IndexPage
