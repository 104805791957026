import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function MainScreen() {
  return (
    <section className="md:pt-8 pt-12 bg-white">
      <div className="px-0 mx-auto max-w-7xl md:mx-2">
        <div className="w-full mx-auto text-left md:w-11/12 xl:w-11/12 md:text-center">
          <h1 className="mb-14 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:tracking-tight">
            <span className="block w-full py-2 mr-3 leading-12 lg:inline">
              Прописи - это лучший способ научиться писать грамотно и красиво
            </span>
          </h1>
          <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
            <a href="#order">
              <button
                type="button"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
              >
                Заказать
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </a>
          </div>
        </div>
        <div className="w-full mx-auto mt-20 text-center md:w-10/12">
          <StaticImage
            src="../images/main.jpg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="propis"
          />
        </div>
      </div>
    </section>
  )
}
