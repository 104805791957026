import React from "react"
import { Link } from "gatsby"

export default function Card({ mockData }) {
  const Cards = ({ card }) => {
    return (
      <div className="container flex flex-col items-center px-5 pt-6 pb-12 mx-auto md:flex-row md:pt-12 md:pb-24 lg:px-28">
        <div className="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
          <h2 className="mb-4 text-xs font-semibold tracking-widest text-black uppercase title-font">
            {" "}
            {card.upTitle}{" "}
          </h2>
          <h1 className="mb-4 text-2xl font-black tracking-tighter text-black md:text-5xl title-font">
            {" "}
            {card.title}{" "}
          </h1>
          <p className="mb-4 text-base leading-relaxed text-left text-blueGray-600 md:py-8">
            {" "}
            {card.description}{" "}
          </p>
          <div className="flex flex-col justify-center lg:flex-row">
            <Link to={card.linkUrl}>
              <button
                type="button"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
              >
                Подробнее
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </Link>
          </div>
        </div>
        <div className="w-full lg:w-1/3 lg:max-w-lg md:w-1/2">
          <img
            className="object-cover object-center rounded-lg h-1200"
            alt="hero"
            src={`${card.url}`}
          />
        </div>
      </div>
    )
  }

  return (
    <section className="text-blueGray-700 bg-white mt-20">
      <div className="h-full">
        {mockData.map(card => (
          <Cards card={card} key={card.id} />
        ))}
      </div>
    </section>
  )
}
